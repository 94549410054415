const positionCustomSelect = document.querySelector('.position.customSelect');
const positionSelectButton = positionCustomSelect.querySelector('.selectButton');
const positionSelectedValue = positionCustomSelect.querySelector('.selectedValue');
const positionSelectRequiredInput = positionCustomSelect.querySelector('.selectRequiredInput');
const positionSelectDropdown = positionCustomSelect.querySelector('.selectDropdown');
const companyName = document.querySelector('.companyName');

const toggleCompanyNameDisplay = (idOfSelectedOption) => {
  if (idOfSelectedOption === '11' || idOfSelectedOption === '14') {
    companyName.classList.add('hide');
    companyName.children[1].removeAttribute('required');
  } else {
    companyName.classList.remove('hide');
    companyName.children[1].setAttribute('required', 'true');
  }
}

positionSelectButton.addEventListener('click', () => {
  positionCustomSelect.classList.toggle('active');
  positionSelectButton.setAttribute(
    'aria-expanded', 
    positionSelectButton.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
  );
}); 

document.addEventListener('DOMContentLoaded', () => {
  const data = JSON.parse(localStorage.genieLanding || '{}');

  fetch('https://stage.geniemap.net/api/v1/landing/claims/positions', { 
    method: 'get',
    headers: {
      'Accept-Language': data.lang ? data.lang.toLowerCase() : 'en'
    }
  })
    .then((res) => res.json())
    .then((positions) => {
      positions.forEach((position) => {
        positionSelectDropdown.innerHTML = `${positionSelectDropdown.innerHTML}
<li role="option">
  <input type="radio" id="${position.id}" name="position">
  <label for="${position.id}">${position.name}</label>
</li>`;
      })

      positionSelectDropdown.querySelectorAll('li').forEach((option) => {
        function handler(e) {
          if (e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) {
            positionSelectedValue.textContent = this.children[1].textContent;
            positionSelectRequiredInput.value = this.children[0].id;
            positionCustomSelect.classList.remove('active');
            toggleCompanyNameDisplay(this.children[0].id)
          }
        }
      
        option.addEventListener('click', handler);
      });
    })  
});
