const countryLabel = document.querySelector('label.country');
const countryCustomSelect = countryLabel.querySelector('.customSelect');
const countrySelectButton = countryCustomSelect.querySelector('.selectButton');
const countrySelectedValue = countryCustomSelect.querySelector('.selectedValue');
const countryPhoneInput = countryLabel.querySelector('.phone');
const countriesList = countryLabel.querySelector('.countries');
const countrySearchInput = countryLabel.querySelector('.countrySearch');

let numberOfCountriesDisplayed = 0;

const performSearchDebounced = debounce((searchTerm) => {
  const searchedCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  renderCountries(searchedCountries);
}, 500);

countrySelectButton.addEventListener('click', () => {
  countryLabel.classList.toggle('active');
  countrySelectButton.setAttribute(
    'aria-expanded', 
    countrySelectButton.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
  );
  if (countryLabel.classList.contains('active')) {
    countrySearchInput.value = '';
    renderCountries(countries);
  }
});

countryPhoneInput.addEventListener('input', function () {
  this.value = this.value.replace(/[^0-9]/g, '');
});

countrySearchInput.addEventListener('keyup', () => {
  performSearchDebounced(countrySearchInput.value);
});

document.addEventListener('DOMContentLoaded', () => {
  renderCountries(countries);
});

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function renderCountries(countries) {
  if (numberOfCountriesDisplayed === countries.length) return;

  numberOfCountriesDisplayed = countries.length;
  countriesList.innerHTML = '';
  countries.forEach((country) => {
    countriesList.innerHTML = `${countriesList.innerHTML}
<li role="option">
  <input type="radio" id="${country.code}" name="country">
  <label for="${country.code}" class="countryFlagWithCode">
    <span class="flag">${country.flag}</span>
    <span class="countryCode">${country.name}</span>
  </label>
</li>`;
  });

  countriesList.querySelectorAll('li').forEach((option) => {
    function handler(e) {
      if (e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) {
        const countryCode = this.children[1].getAttribute('for');
        const selectedCountry = countries.find(
          (country) => country.code === countryCode
        );
        countrySelectedValue.querySelector('.flag').textContent =
          selectedCountry.flag;
        countrySelectedValue.querySelector('.countryCode').textContent =
          selectedCountry.phone.code;
        countryLabel.classList.remove('active');
      }
    }

    option.addEventListener('click', handler);
  });
}
