const countries = [
  {
    code: 'AC',
    name: 'Ascension',
    flag: '🇦🇨',
    phone: {
      code: '+247',
    },
  },
  {
    code: 'AD',
    name: 'Andorra',
    flag: '🇦🇩',
    phone: {
      code: '+376',
    },
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    phone: {
      code: '+971',
    },
  },
  {
    code: 'AF',
    name: 'Afghanistan',
    flag: '🇦🇫',
    phone: {
      code: '+93',
    },
  },
  {
    code: 'AG',
    name: 'Antigua & Barbuda',
    flag: '🇦🇬',
    phone: {
      code: '+1268',
    },
  },
  {
    code: 'AI',
    name: 'Anguilla',
    flag: '🇦🇮',
    phone: {
      code: '+1264',
    },
  },
  {
    code: 'AL',
    name: 'Albania',
    flag: '🇦🇱',
    phone: {
      code: '+355',
    },
  },
  {
    code: 'AM',
    name: 'Armenia',
    flag: '🇦🇲',
    phone: {
      code: '+374',
    },
  },
  {
    code: 'AO',
    name: 'Angola',
    flag: '🇦🇴',
    phone: {
      code: '+244',
    },
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    flag: '🇦🇶',
    phone: {
      code: '+6721',
    },
  },
  {
    code: 'AR',
    name: 'Argentina',
    flag: '🇦🇷',
    phone: {
      code: '+54',
    },
  },
  {
    code: 'AS',
    name: 'American Samoa',
    flag: '🇦🇸',
    phone: {
      code: '+1684',
    },
  },
  {
    code: 'AT',
    name: 'Austria',
    flag: '🇦🇹',
    phone: {
      code: '+43',
    },
  },
  {
    code: 'AU',
    name: 'Australia',
    flag: '🇦🇺',
    phone: {
      code: '+61',
    },
  },
  {
    code: 'AW',
    name: 'Aruba',
    flag: '🇦🇼',
    phone: {
      code: '+297',
    },
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    flag: '🇦🇿',
    phone: {
      code: '+994',
    },
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    phone: {
      code: '+387',
    },
  },
  {
    code: 'BB',
    name: 'Barbados',
    flag: '🇧🇧',
    phone: {
      code: '+1246',
    },
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    flag: '🇧🇩',
    phone: {
      code: '+880',
    },
  },
  {
    code: 'BE',
    name: 'Belgium',
    flag: '🇧🇪',
    phone: {
      code: '+32',
    },
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    flag: '🇧🇫',
    phone: {
      code: '+226',
    },
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    flag: '🇧🇬',
    phone: {
      code: '+359',
    },
  },
  {
    code: 'BH',
    name: 'Bahrain',
    flag: '🇧🇭',
    phone: {
      code: '+973',
    },
  },
  {
    code: 'BI',
    name: 'Burundi',
    flag: '🇧🇮',
    phone: {
      code: '+257',
    },
  },
  {
    code: 'BJ',
    name: 'Benin',
    flag: '🇧🇯',
    phone: {
      code: '+229',
    },
  },
  {
    code: 'BM',
    name: 'Bermuda',
    flag: '🇧🇲',
    phone: {
      code: '+1441',
    },
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    flag: '🇧🇳',
    phone: {
      code: '+673',
    },
  },
  {
    code: 'BO',
    name: 'Bolivia',
    flag: '🇧🇴',
    phone: {
      code: '+591',
    },
  },
  {
    code: 'BR',
    name: 'Brazil',
    flag: '🇧🇷',
    phone: {
      code: '+55',
    },
  },
  {
    code: 'BS',
    name: 'Bahamas',
    flag: '🇧🇸',
    phone: {
      code: '+1242',
    },
  },
  {
    code: 'BT',
    name: 'Bhutan',
    flag: '🇧🇹',
    phone: {
      code: '+975',
    },
  },
  {
    code: 'BW',
    name: 'Botswana',
    flag: '🇧🇼',
    phone: {
      code: '+267',
    },
  },
  {
    code: 'BY',
    name: 'Belarus',
    flag: '🇧🇾',
    phone: {
      code: '+375',
    },
  },
  {
    code: 'BZ',
    name: 'Belize',
    flag: '🇧🇿',
    phone: {
      code: '+501',
    },
  },
  {
    code: 'CA',
    name: 'Canada',
    flag: '🇨🇦',
    phone: {
      code: '+1',
    },
  },
  {
    code: 'CD',
    name: 'Congo Dem. Rep. ',
    flag: '🇨🇩',
    phone: {
      code: '+243',
    },
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    flag: '🇨🇫',
    phone: {
      code: '+236',
    },
  },
  {
    code: 'CG',
    name: 'Congo',
    flag: '🇨🇬',
    phone: {
      code: '+242',
    },
  },
  {
    code: 'CH',
    name: 'Switzerland',
    flag: '🇨🇭',
    phone: {
      code: '+41',
    },
  },
  {
    code: 'CI',
    name: 'Cote D\'Ivoire',
    flag: '🇨🇮',
    phone: {
      code: '+225',
    },
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    flag: '🇨🇰',
    phone: {
      code: '+682',
    },
  },
  {
    code: 'CL',
    name: 'Chile',
    flag: '🇨🇱',
    phone: {
      code: '+56',
    },
  },
  {
    code: 'CM',
    name: 'Cameroon',
    flag: '🇨🇲',
    phone: {
      code: '+237',
    },
  },
  {
    code: 'CN',
    name: 'China',
    flag: '🇨🇳',
    phone: {
      code: '+86',
    },
  },
  {
    code: 'CO',
    name: 'Colombia',
    flag: '🇨🇴',
    phone: {
      code: '+57',
    },
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    flag: '🇨🇷',
    phone: {
      code: '+506',
    },
  },
  {
    code: 'CU',
    name: 'Cuba',
    flag: '🇨🇺',
    phone: {
      code: '+53',
    },
  },
  {
    code: 'CV',
    name: 'Cape Verde',
    flag: '🇨🇻',
    phone: {
      code: '+238',
    },
  },
  {
    code: 'CW',
    name: 'Curaçao',
    flag: '🇨🇼',
    phone: {
      code: '+599',
    },
  },
  {
    code: 'CY',
    name: 'Cyprus',
    flag: '🇨🇾',
    phone: {
      code: '+357',
    },
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    flag: '🇨🇿',
    phone: {
      code: '+420',
    },
  },
  {
    code: 'DE',
    name: 'Germany',
    flag: '🇩🇪',
    phone: {
      code: '+49',
    },
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    flag: '🇩🇯',
    phone: {
      code: '+253',
    },
  },
  {
    code: 'DK',
    name: 'Denmark',
    flag: '🇩🇰',
    phone: {
      code: '+45',
    },
  },
  {
    code: 'DM',
    name: 'Dominica',
    flag: '🇩🇲',
    phone: {
      code: '+1767',
    },
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    flag: '🇩🇴',
    phone: {
      code: '+1',
    },
  },
  {
    code: 'DZ',
    name: 'Algeria',
    flag: '🇩🇿',
    phone: {
      code: '+213',
    },
  },
  {
    code: 'EC',
    name: 'Ecuador',
    flag: '🇪🇨',
    phone: {
      code: '+593',
    },
  },
  {
    code: 'EE',
    name: 'Estonia',
    flag: '🇪🇪',
    phone: {
      code: '+372',
    },
  },
  {
    code: 'EG',
    name: 'Egypt',
    flag: '🇪🇬',
    phone: {
      code: '+20',
    },
  },
  {
    code: 'ER',
    name: 'Eritrea',
    flag: '🇪🇷',
    phone: {
      code: '+291',
    },
  },
  {
    code: 'ES',
    name: 'Spain',
    flag: '🇪🇸',
    phone: {
      code: '+34',
    },
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    flag: '🇪🇹',
    phone: {
      code: '+251',
    },
  },
  {
    code: 'FI',
    name: 'Finland',
    flag: '🇫🇮',
    phone: {
      code: '+358',
    },
  },
  {
    code: 'FJ',
    name: 'Fiji',
    flag: '🇫🇯',
    phone: {
      code: '+679',
    },
  },
  {
    code: 'FK',
    name: 'Falkland Islands Malvinas',
    flag: '🇫🇰',
    phone: {
      code: '+500',
    },
  },
  {
    code: 'FM',
    name: 'Micronesia',
    flag: '🇫🇲',
    phone: {
      code: '+691',
    },
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    flag: '🇫🇴',
    phone: {
      code: '+298',
    },
  },
  {
    code: 'FR',
    name: 'France',
    flag: '🇫🇷',
    phone: {
      code: '+33',
    },
  },
  {
    code: 'FR',
    name: 'Réunion',
    flag: '🇫🇷',
    phone: {
      code: '+262',
    },
  },
  {
    code: 'FR',
    name: 'Saint Pierre & Miquelon',
    flag: '🇫🇷',
    phone: {
      code: '+508',
    },
  },
  {
    code: 'FR',
    name: 'Guadelope',
    flag: '🇫🇷',
    phone: {
      code: '+590',
    },
  },
  {
    code: 'GA',
    name: 'Gabon',
    flag: '🇬🇦',
    phone: {
      code: '+241',
    },
  },
  {
    code: 'GD',
    name: 'Grenada',
    flag: '🇬🇩',
    phone: {
      code: '+1473',
    },
  },
  {
    code: 'GE',
    name: 'Georgia',
    flag: '🇬🇪',
    phone: {
      code: '+-95',
    },
  },
  {
    code: 'GF',
    name: 'French Guiana',
    flag: '🇬🇫',
    phone: {
      code: '+594',
    },
  },
  {
    code: 'GH',
    name: 'Ghana',
    flag: '🇬🇭',
    phone: {
      code: '+233',
    },
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    flag: '🇬🇮',
    phone: {
      code: '+350',
    },
  },
  {
    code: 'GL',
    name: 'Greenland',
    flag: '🇬🇱',
    phone: {
      code: '+299',
    },
  },
  {
    code: 'GM',
    name: 'Gambia',
    flag: '🇬🇲',
    phone: {
      code: '+220',
    },
  },
  {
    code: 'GN',
    name: 'Guinea',
    flag: '🇬🇳',
    phone: {
      code: '+224',
    },
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
    phone: {
      code: '+240',
    },
  },
  {
    code: 'GR',
    name: 'Greece',
    flag: '🇬🇷',
    phone: {
      code: '+30',
    },
  },
  {
    code: 'GT',
    name: 'Guatemala',
    flag: '🇬🇹',
    phone: {
      code: '+502',
    },
  },
  {
    code: 'GU',
    name: 'Guam',
    flag: '🇬🇺',
    phone: {
      code: '+1671',
    },
  },
  {
    code: 'GW',
    name: 'Guinea Bissau',
    flag: '🇬🇼',
    phone: {
      code: '+245',
    },
  },
  {
    code: 'GY',
    name: 'Guyana',
    flag: '🇬🇾',
    phone: {
      code: '+592',
    },
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    flag: '🇭🇰',
    phone: {
      code: '+852',
    },
  },
  {
    code: 'HN',
    name: 'Honduras',
    flag: '🇭🇳',
    phone: {
      code: '+504',
    },
  },
  {
    code: 'HR',
    name: 'Croatia',
    flag: '🇭🇷',
    phone: {
      code: '+385',
    },
  },
  {
    code: 'HT',
    name: 'Haiti',
    flag: '🇭🇹',
    phone: {
      code: '+509',
    },
  },
  {
    code: 'HU',
    name: 'Hungary',
    flag: '🇭🇺',
    phone: {
      code: '+36',
    },
  },
  {
    code: 'ID',
    name: 'Indonesia',
    flag: '🇮🇩',
    phone: {
      code: '+62',
    },
  },
  {
    code: 'IE',
    name: 'Ireland',
    flag: '🇮🇪',
    phone: {
      code: '+353',
    },
  },
  {
    code: 'IL',
    name: 'Israel',
    flag: '🇮🇱',
    phone: {
      code: '+972',
    },
  },
  {
    code: 'IN',
    name: 'India',
    flag: '🇮🇳',
    phone: {
      code: '+91',
    },
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    phone: {
      code: '+246',
    },
  },
  {
    code: 'IQ',
    name: 'Iraq',
    flag: '🇮🇶',
    phone: {
      code: '+964',
    },
  },
  {
    code: 'IR',
    name: 'Iran',
    flag: '🇮🇷',
    phone: {
      code: '+98',
    },
  },
  {
    code: 'IS',
    name: 'Iceland',
    flag: '🇮🇸',
    phone: {
      code: '+354',
    },
  },
  {
    code: 'IT',
    name: 'Italy',
    flag: '🇮🇹',
    phone: {
      code: '+39',
    },
  },
  {
    code: 'JM',
    name: 'Jamaica',
    flag: '🇯🇲',
    phone: {
      code: '+1876',
    },
  },
  {
    code: 'JO',
    name: 'Jordan',
    flag: '🇯🇴',
    phone: {
      code: '+962',
    },
  },
  {
    code: 'JP',
    name: 'Japan',
    flag: '🇯🇵',
    phone: {
      code: '+81',
    },
  },
  {
    code: 'KE',
    name: 'Kenya',
    flag: '🇰🇪',
    phone: {
      code: '+254',
    },
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
    phone: {
      code: '+-96',
    },
  },
  {
    code: 'KH',
    name: 'Cambodia',
    flag: '🇰🇭',
    phone: {
      code: '+855',
    },
  },
  {
    code: 'KI',
    name: 'Kiribati',
    flag: '🇰🇮',
    phone: {
      code: '+686',
    },
  },
  {
    code: 'KM',
    name: 'Comoros',
    flag: '🇰🇲',
    phone: {
      code: '+269',
    },
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    phone: {
      code: '+1869',
    },
  },
  {
    code: 'KP',
    name: 'North Korea',
    flag: '🇰🇵',
    phone: {
      code: '+850',
    },
  },
  {
    code: 'KR',
    name: 'South Korea',
    flag: '🇰🇷',
    phone: {
      code: '+82',
    },
  },
  {
    code: 'KW',
    name: 'Kuwait',
    flag: '🇰🇼',
    phone: {
      code: '+965',
    },
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    flag: '🇰🇾',
    phone: {
      code: '+1345',
    },
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    flag: '🇰🇿',
    phone: {
      code: '+7',
    },
  },
  {
    code: 'LA',
    name: 'Laos',
    flag: '🇱🇦',
    phone: {
      code: '+856',
    },
  },
  {
    code: 'LB',
    name: 'Lebanon',
    flag: '🇱🇧',
    phone: {
      code: '+961',
    },
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    flag: '🇱🇨',
    phone: {
      code: '+1758',
    },
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    flag: '🇱🇮',
    phone: {
      code: '+423',
    },
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    flag: '🇱🇰',
    phone: {
      code: '+94',
    },
  },
  {
    code: 'LR',
    name: 'Liberia',
    flag: '🇱🇷',
    phone: {
      code: '+231',
    },
  },
  {
    code: 'LS',
    name: 'Lesotho',
    flag: '🇱🇸',
    phone: {
      code: '+266',
    },
  },
  {
    code: 'LT',
    name: 'Lithuania',
    flag: '🇱🇹',
    phone: {
      code: '+370',
    },
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    flag: '🇱🇺',
    phone: {
      code: '+352',
    },
  },
  {
    code: 'LV',
    name: 'Latvia',
    flag: '🇱🇻',
    phone: {
      code: '+371',
    },
  },
  {
    code: 'LY',
    name: 'Libyan Arab Jamahiriya',
    flag: '🇱🇾',
    phone: {
      code: '+218',
    },
  },
  {
    code: 'MA',
    name: 'Morocco',
    flag: '🇲🇦',
    phone: {
      code: '+212',
    },
  },
  {
    code: 'MC',
    name: 'Monaco',
    flag: '🇲🇨',
    phone: {
      code: '+377',
    },
  },
  {
    code: 'MD',
    name: 'Moldova',
    flag: '🇲🇩',
    phone: {
      code: '+373',
    },
  },
  {
    code: 'ME',
    name: 'Montenegro',
    flag: '🇲🇪',
    phone: {
      code: '+382',
    },
  },
  {
    code: 'MG',
    name: 'Madagascar',
    flag: '🇲🇬',
    phone: {
      code: '+261',
    },
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    flag: '🇲🇭',
    phone: {
      code: '+692',
    },
  },
  {
    code: 'MK',
    name: 'North Macedonia',
    flag: '🇲🇰',
    phone: {
      code: '+389',
    },
  },
  {
    code: 'ML',
    name: 'Mali',
    flag: '🇲🇱',
    phone: {
      code: '+223',
    },
  },
  {
    code: 'MM',
    name: 'Myanmar',
    flag: '🇲🇲',
    phone: {
      code: '+95',
    },
  },
  {
    code: 'MN',
    name: 'Mongolia',
    flag: '🇲🇳',
    phone: {
      code: '+976',
    },
  },
  {
    code: 'MO',
    name: 'Macao',
    flag: '🇲🇴',
    phone: {
      code: '+853',
    },
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    phone: {
      code: '+1670',
    },
  },
  {
    code: 'MQ',
    name: 'Martinique',
    flag: '🇲🇶',
    phone: {
      code: '+596',
    },
  },
  {
    code: 'MR',
    name: 'Mauritania',
    flag: '🇲🇷',
    phone: {
      code: '+222',
    },
  },
  {
    code: 'MS',
    name: 'Montserrat',
    flag: '🇲🇸',
    phone: {
      code: '+1664',
    },
  },
  {
    code: 'MT',
    name: 'Malta',
    flag: '🇲🇹',
    phone: {
      code: '+356',
    },
  },
  {
    code: 'MU',
    name: 'Mauritius',
    flag: '🇲🇺',
    phone: {
      code: '+230',
    },
  },
  {
    code: 'MV',
    name: 'Maldives',
    flag: '🇲🇻',
    phone: {
      code: '+960',
    },
  },
  {
    code: 'MW',
    name: 'Malawi',
    flag: '🇲🇼',
    phone: {
      code: '+2651',
    },
  },
  {
    code: 'MX',
    name: 'Mexico',
    flag: '🇲🇽',
    phone: {
      code: '+52',
    },
  },
  {
    code: 'MY',
    name: 'Malaysia',
    flag: '🇲🇾',
    phone: {
      code: '+60',
    },
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    flag: '🇲🇿',
    phone: {
      code: '+258',
    },
  },
  {
    code: 'NA',
    name: 'Namibia',
    flag: '🇳🇦',
    phone: {
      code: '+264',
    },
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    flag: '🇳🇨',
    phone: {
      code: '+687',
    },
  },
  {
    code: 'NE',
    name: 'Niger',
    flag: '🇳🇪',
    phone: {
      code: '+227',
    },
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    flag: '🇳🇫',
    phone: {
      code: '+6723',
    },
  },
  {
    code: 'NG',
    name: 'Nigeria',
    flag: '🇳🇬',
    phone: {
      code: '+234',
    },
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    flag: '🇳🇮',
    phone: {
      code: '+505',
    },
  },
  {
    code: 'NL',
    name: 'Netherlands',
    flag: '🇳🇱',
    phone: {
      code: '+31',
    },
  },
  {
    code: 'NO',
    name: 'Norway',
    flag: '🇳🇴',
    phone: {
      code: '+47',
    },
  },
  {
    code: 'NP',
    name: 'Nepal',
    flag: '🇳🇵',
    phone: {
      code: '+977',
    },
  },
  {
    code: 'NR',
    name: 'Nauru',
    flag: '🇳🇷',
    phone: {
      code: '+674',
    },
  },
  {
    code: 'NU',
    name: 'Niue',
    flag: '🇳🇺',
    phone: {
      code: '+683',
    },
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    flag: '🇳🇿',
    phone: {
      code: '+64',
    },
  },
  {
    code: 'OM',
    name: 'Oman',
    flag: '🇴🇲',
    phone: {
      code: '+968',
    },
  },
  {
    code: 'PA',
    name: 'Panama',
    flag: '🇵🇦',
    phone: {
      code: '+507',
    },
  },
  {
    code: 'PE',
    name: 'Peru',
    flag: '🇵🇪',
    phone: {
      code: '+51',
    },
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    flag: '🇵🇫',
    phone: {
      code: '+689',
    },
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    flag: '🇵🇬',
    phone: {
      code: '+675',
    },
  },
  {
    code: 'PH',
    name: 'Philippines',
    flag: '🇵🇭',
    phone: {
      code: '+63',
    },
  },
  {
    code: 'PK',
    name: 'Pakistan',
    flag: '🇵🇰',
    phone: {
      code: '+92',
    },
  },
  {
    code: 'PL',
    name: 'Poland',
    flag: '🇵🇱',
    phone: {
      code: '+48',
    },
  },
  {
    code: 'PS',
    name: 'Palestine',
    flag: '🇵🇸',
    phone: {
      code: '+970',
    },
  },
  {
    code: 'PT',
    name: 'Portugal',
    flag: '🇵🇹',
    phone: {
      code: '+351',
    },
  },
  {
    code: 'PW',
    name: 'Palau',
    flag: '🇵🇼',
    phone: {
      code: '+680',
    },
  },
  {
    code: 'PY',
    name: 'Paraguay',
    flag: '🇵🇾',
    phone: {
      code: '+595',
    },
  },
  {
    code: 'QA',
    name: 'Qatar',
    flag: '🇶🇦',
    phone: {
      code: '+974',
    },
  },
  {
    code: 'RE',
    name: 'Reunion',
    flag: '🇷🇪',
    phone: {
      code: '+262',
    },
  },
  {
    code: 'RO',
    name: 'Romania',
    flag: '🇷🇴',
    phone: {
      code: '+40',
    },
  },
  {
    code: 'RS',
    name: 'Serbia',
    flag: '🇷🇸',
    phone: {
      code: '+381',
    },
  },
  {
    code: 'RU',
    name: 'Russian Federation',
    flag: '🇷🇺',
    phone: {
      code: '+7',
    },
  },
  {
    code: 'RW',
    name: 'Rwanda',
    flag: '🇷🇼',
    phone: {
      code: '+250',
    },
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    flag: '🇸🇦',
    phone: {
      code: '+966',
    },
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    flag: '🇸🇧',
    phone: {
      code: '+677',
    },
  },
  {
    code: 'SC',
    name: 'Seychelles',
    flag: '🇸🇨',
    phone: {
      code: '+248',
    },
  },
  {
    code: 'SD',
    name: 'Sudan',
    flag: '🇸🇩',
    phone: {
      code: '+249',
    },
  },
  {
    code: 'SE',
    name: 'Sweden',
    flag: '🇸🇪',
    phone: {
      code: '+46',
    },
  },
  {
    code: 'SG',
    name: 'Singapore',
    flag: '🇸🇬',
    phone: {
      code: '+65',
    },
  },
  {
    code: 'SH',
    name: 'Saint Helena',
    flag: '🇸🇭',
    phone: {
      code: '+290',
    },
  },
  {
    code: 'SI',
    name: 'Slovenia',
    flag: '🇸🇮',
    phone: {
      code: '+386',
    },
  },
  {
    code: 'SK',
    name: 'Slovakia',
    flag: '🇸🇰',
    phone: {
      code: '+421',
    },
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    flag: '🇸🇱',
    phone: {
      code: '+232',
    },
  },
  {
    code: 'SM',
    name: 'San Marino',
    flag: '🇸🇲',
    phone: {
      code: '+378',
    },
  },
  {
    code: 'SN',
    name: 'Senegal',
    flag: '🇸🇳',
    phone: {
      code: '+221',
    },
  },
  {
    code: 'SO',
    name: 'Somalia',
    flag: '🇸🇴',
    phone: {
      code: '+252',
    },
  },
  {
    code: 'SR',
    name: 'Suriname',
    flag: '🇸🇷',
    phone: {
      code: '+597',
    },
  },
  {
    code: 'SS',
    name: 'South Sudan',
    flag: '🇸🇸',
    phone: {
      code: '+211',
    },
  },
  {
    code: 'ST',
    name: 'São Tomé & Príncipe',
    flag: '🇸🇹',
    phone: {
      code: '+239',
    },
  },
  {
    code: 'SV',
    name: 'El Salvador',
    flag: '🇸🇻',
    phone: {
      code: '+503',
    },
  },
  {
    code: 'SX',
    name: 'Sint Maarten',
    flag: '🇸🇽',
    phone: {
      code: '+1721',
    },
  },
  {
    code: 'SY',
    name: 'Syrian Arab Republic',
    flag: '🇸🇾',
    phone: {
      code: '+963',
    },
  },
  {
    code: 'SZ',
    name: 'Swaziland',
    flag: '🇸🇿',
    phone: {
      code: '+268',
    },
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    phone: {
      code: '+1649',
    },
  },
  {
    code: 'TD',
    name: 'Chad',
    flag: '🇹🇩',
    phone: {
      code: '+235',
    },
  },
  {
    code: 'TG',
    name: 'Togo',
    flag: '🇹🇬',
    phone: {
      code: '+228',
    },
  },
  {
    code: 'TH',
    name: 'Thailand',
    flag: '🇹🇭',
    phone: {
      code: '+66',
    },
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    flag: '🇹🇯',
    phone: {
      code: '+992',
    },
  },
  {
    code: 'TK',
    name: 'Tokelau',
    flag: '🇹🇰',
    phone: {
      code: '+690',
    },
  },
  {
    code: 'TL',
    name: 'Timor Leste',
    flag: '🇹🇱',
    phone: {
      code: '+670',
    },
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    flag: '🇹🇲',
    phone: {
      code: '+-93',
    },
  },
  {
    code: 'TN',
    name: 'Tunisia',
    flag: '🇹🇳',
    phone: {
      code: '+216',
    },
  },
  {
    code: 'TO',
    name: 'Tonga',
    flag: '🇹🇴',
    phone: {
      code: '+676',
    },
  },
  {
    code: 'TR',
    name: 'Turkey',
    flag: '🇹🇷',
    phone: {
      code: '+90',
    },
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
    phone: {
      code: '+1868',
    },
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    flag: '🇹🇻',
    phone: {
      code: '+688',
    },
  },
  {
    code: 'TW',
    name: 'Taiwan, Province of China',
    flag: '🇹🇼',
    phone: {
      code: '+886',
    },
  },
  {
    code: 'TZ',
    name: 'Tanzania',
    flag: '🇹🇿',
    phone: {
      code: '+255',
    },
  },
  {
    code: 'UA',
    name: 'Ukraine',
    flag: '🇺🇦',
    phone: {
      code: '+380',
    },
  },
  {
    code: 'UG',
    name: 'Uganda',
    flag: '🇺🇬',
    phone: {
      code: '+256',
    },
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    flag: '🇬🇧',
    phone: {
      code: '+44',
    },
  },
  {
    code: 'US',
    name: 'United States',
    flag: '🇺🇸',
    phone: {
      code: '+1',
    },
  },
  {
    code: 'UY',
    name: 'Uruguay',
    flag: '🇺🇾',
    phone: {
      code: '+598',
    },
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    flag: '🇺🇿',
    phone: {
      code: '+-98',
    },
  },
  {
    code: 'VA',
    name: 'Holy See Vatican City State ',
    flag: '🇻🇦',
    phone: {
      code: '+39',
    },
  },
  {
    code: 'VC',
    name: 'Saint Vincent & the Grenadines',
    flag: '🇻🇨',
    phone: {
      code: '+1784',
    },
  },
  {
    code: 'VE',
    name: 'Venezuela',
    flag: '🇻🇪',
    phone: {
      code: '+58',
    },
  },
  {
    code: 'VG',
    name: 'Virgin Islands, British',
    flag: '🇻🇬',
    phone: {
      code: '+1284',
    },
  },
  {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    phone: {
      code: '+1340',
    },
  },
  {
    code: 'VN',
    name: 'Vietnam',
    flag: '🇻🇳',
    phone: {
      code: '+84',
    },
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    flag: '🇻🇺',
    phone: {
      code: '+678',
    },
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
    phone: {
      code: '+681',
    },
  },
  {
    code: 'WS',
    name: 'Samoa',
    flag: '🇼🇸',
    phone: {
      code: '+685',
    },
  },
  {
    code: 'YE',
    name: 'Yemen',
    flag: '🇾🇪',
    phone: {
      code: '+967',
    },
  },
  {
    code: 'ZA',
    name: 'South Africa',
    flag: '🇿🇦',
    phone: {
      code: '+27',
    },
  },
  {
    code: 'ZM',
    name: 'Zambia',
    flag: '🇿🇲',
    phone: {
      code: '+260',
    },
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    flag: '🇿🇼',
    phone: {
      code: '+263',
    },
  },
];
